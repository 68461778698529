const items = [
  {
    title: "Home",
    ref: "/",
  },
  {
    title: "Gallery",
    ref: "/gallery",
  },
  {
    title: "Menu",
    ref: "/menu",
  },
  {
    title: "Contact",
    ref: "/contact",
  },
]

export default items
